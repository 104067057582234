<!--运行维护-数据异常处理-->
<template>
  <div class="abnormal_data_page">
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-select
              @change="netGetMessageList"
              v-model="filter.LocaleId"
              filterable
              remote
              clearable
              :remote-method="remoteMethod"
              placeholder='监测点'
            >
              <el-option
                v-for="item in locale"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="netGetMessageList"
              v-model="filter.ErrorDesc"
              filterable
              clearable
              placeholder='异常状态'
            >
              <el-option
                v-for="item in maintenanceErrorDescOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              placeholder="所属单位"
              @change="netGetMessageList"
              v-model="filter.Owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable/>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="netGetMessageList"
              v-model="filter.Status"
              filterable
              clearable
              placeholder='操作状态'
            >
              <el-option
                v-for="item in maintenanceStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警日期">
            <el-date-picker
              type="daterange"
              v-model="filter.AlarmAt"
              @change="netGetMessageList"
              format="yyyy-MM-dd"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="处理日期">
            <el-date-picker
              type="daterange"
              v-model="filter.MaintainAt"
              @change="netGetMessageList"
              format="yyyy-MM-dd"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table  :max-height="clientHeight" :data='dataObj.list' size='mini' border highlight-current-row v-loading='loading' style='width: 100%'>
      <el-table-column type='index' label='#' align='center' width='55'/>
      <el-table-column prop='LocaleName' label='监测点' show-overflow-tooltip header-align='center'></el-table-column>
      <el-table-column prop='Addr' label='安装地址' show-overflow-tooltip
                       header-align='center' min-width="150"></el-table-column>
      <el-table-column prop='MN' label='设备编号' width="230" align='center'></el-table-column>
      <el-table-column prop='ErrorDesc' label='异常状态' align='center' width="100"></el-table-column>
      <el-table-column prop='AlarmAt' label='报警日期' :formatter="dateFormatter" align='center'
                       width="110"></el-table-column>
      <el-table-column prop='MaintainerName' label='运维人员' show-overflow-tooltip
                       align="center" header-align='center' width="100"></el-table-column>
      <el-table-column prop="MaintainAt" :formatter="dateFormatter" label="处理日期"
                       align="center" width="110" header-align="center"></el-table-column>
      <el-table-column
        prop="Handler"
        label="处理人员"
        align="center"
        width="100"
        header-align="center"
      />
      <el-table-column
        prop="Status"
        label="操作状态"
        :formatter="maintenanceStatusFormatter"
        align="center"
        width="100"
        header-align="center"
      ></el-table-column>
      <el-table-column
        prop="AbnormalCause"
        label="异常原因"
        align="center"
        width="100"
        header-align="center"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="120"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleDeal(scope.$index, scope.row)"
                     :disabled="scope.row.Status === 1 || (role !== 'sa' && role !== 'Maintainer' && role !== 'Charger')">
            处理
          </el-button>
          <el-button type="text" size="mini" @click="handleLook(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 处理-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
      width="550px"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="84px"
        label-position="left"
        :disabled="isView"
      >
        <el-form-item prop="ErrorDesc" label="异常状态">
          <el-input
            v-model="formData.ErrorDesc"
            disabled
            placeholder="异常状态"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="LocaleId" label="监测点名称">
          <el-input
            v-model="formData.LocaleName"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="LocalAddr" label="监测点地址">
          <el-input
            v-model="formData.Addr"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="MN" label="设备编号">
          <el-input
            v-model="formData.MN"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="AlarmAt" label="报警日期">
          <el-date-picker
            disabled
            placeholder="无"
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="formData.AlarmAt"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="MaintainerName" label="运维人员">
          <el-input
            v-model="formData.MaintainerName"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="MaintainAt" label="处理日期">
          <el-date-picker
            disabled
            placeholder="无"
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="formData.MaintainAt"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="AbnormalCause" label="异常原因">
          <el-select
            v-model="formData.AbnormalCause"
            @change="hasChange"
            :disabled="!isDeal"
            placeholder="无"
            v-if="formData.ErrorDesc === '异常离线'"
          >
            <el-option
              v-for="item in maintenanceAbnormalCauseOptionsTypeTwo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="formData.AbnormalCause"
            @change="hasChange"
            :disabled="!isDeal"
            placeholder="无"
            v-else
          >
            <el-option
              v-for="item in maintenanceAbnormalCauseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="MaintenanceTimeSpan" label="持续天数">
          <el-select
            v-model="formData.MaintenanceTimeSpan"
            @change="hasChange"
            :disabled="!isDeal"
            placeholder="无"
          >
            <el-option
              v-for="item in maintenanceTimeSpanOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="Resolution" label="处理措施及结果">
          <el-input
            @change="hasChange"
            type="textarea"
            :rows="2"
            autosize
            v-model.trim="formData.Resolution"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Pos" label="定位">
          <baidu-map
            class="bm-view"
            :center="mapCenter"
            :zoom="mapZoom"
            ak="770b4c07458f53896ff0abd948755e20"
            @ready="handleMapReady"
            v-if="isDeal"
          >
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
              @locationSuccess="getPosition"
            ></bm-geolocation>
          </baidu-map>
          <span>{{ formData.Position ? formData.Position : '暂无定位' }}</span>
        </el-form-item>
        <el-form-item>
          <el-image
            class="view-img"
            v-for="(item, i) in fileList"
            :key="i"
            :src="item"
            :preview-src-list="fileList"
          >
            <div slot="error" class="image-slot">
              暂无图片
            </div>
          </el-image>
        </el-form-item>
        <el-form-item>
          <el-upload
            ref="upload"
            :disabled="!isDeal"
            accept=".jpg, .jpeg, .png"
            list-type="picture-card"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :limit="4"
            :before-upload="hasChange"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
          >
            <i class="el-icon-plus"/>
            <div slot="tip">上传照片（最多4张，支持jpg、jpeg、png）</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="isDeal">
        <el-button size="mini" @click.native="dlg.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click.native="handleSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--    图片展示-->
    <el-dialog :visible.sync="showImg">
      <el-image
        :src="dialogImageUrl"
        fit="fill"
      >
        <div slot="error" class="image-slot">
          暂无图片
        </div>
      </el-image>
    </el-dialog>
    <!-- 工具条 -->
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.page"
        :page-sizes="filter.pageSizes"
        :page-size="filter.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater,
  getUserInfo,
  join,
  ifNull,
  maintenanceStatusFormatter,
  maintenanceStatusOptions,
  maintenanceErrorDescOptions,
  maintenanceAbnormalCauseOptions,
  maintenanceAbnormalCauseOptionsTypeTwo,
  maintenanceTimeSpanOptions,
  export_json, ownerJoin
} from '@/util/index'
import conf from '@/config'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'

const assembleParam = (param) => {
  const r = {
    StartAt: (param.page - 1) * param.size,
    Size: param.size,
    FormType: 3,
    Param: {}
  }
  if (param.LocaleId) {
    r.Param['maintenance.locale_id'] = {S: 2, V: param.LocaleId.toString()}
  }
  if (param.ErrorDesc) {
    r.Param['maintenance.error_desc'] = {S: 2, V: param.ErrorDesc.toString()}
  }
  if (param.Owner && param.Owner.length > 0) {
    const owner = `${ownerJoin(param.Owner, param.userInfo)}`
    r.Param['maintenance.owner'] = {S: 4, V: owner}
  }
  if (param.AlarmAt) {
    r.Param['maintenance.alarm_at'] = []
    r.Param['maintenance.alarm_at'].push(0 | param.AlarmAt[0] / 1000)
    r.Param['maintenance.alarm_at'].push((0 | param.AlarmAt[1] / 1000) + 86400)
    r.Param['maintenance.alarm_at'] = {S: 11, V: r.Param['maintenance.alarm_at'].toString()}
  }
  if (param.MaintainAt) {
    r.Param['maintenance.maintain_at'] = []
    r.Param['maintenance.maintain_at'].push(0 | param.MaintainAt[0] / 1000)
    r.Param['maintenance.maintain_at'].push((0 | param.MaintainAt[1] / 1000) + 86400)
    r.Param['maintenance.maintain_at'] = {S: 11, V: r.Param['maintenance.maintain_at'].toString()}
  }
  if (param.Status || param.Status === 2) {
    r.Param['maintenance.status'] = {S: 0, V: param.Status.toString()}
  }
  return r
}

export default {
  components: {
    BaiduMap,
    BmGeolocation
  },
  data() {
    return {
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
      maintenanceStatusOptions,
      maintenanceErrorDescOptions,
      maintenanceAbnormalCauseOptions,
      maintenanceAbnormalCauseOptionsTypeTwo,
      maintenanceTimeSpanOptions,
      filter: {
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
        exportSize: 1000
      },
      PanicRangeTime: [], // 异常日期时间，自用
      HandleRangeTime: [], // 处理日期时间，自用,
      loading: false,
      exportLoading: false,
      userInfo: null,
      showImg: false,
      dialogImageUrl: null,
      dataObj: {
        list: [],
        pages: 0,
        total: 0
      },
      dlg: {
        visible: false,
        type: null,
        title: ''
      },
      MaintainerList: [],
      formData: {},
      formRule: {
        AbnormalCause: [{required: true, message: '异常原因不可为空', trigger: 'blur'}],
        MaintenanceTimeSpan: [{required: true, message: '持续天数不可为空', trigger: 'blur'}],
        Resolution: [{required: true, message: '处理措施不可为空'}]
      },
      // 操作异常数据入参
      updatePostdata: {
        show: false, // 是哦福显示派单对话框
        loading: false, // 数据请求加载状态
        Id: null,
        Status: null, // 操作状态：2 // 异常数据 已派单；3 // 异常数据 正常
        Resolution: '' // 操作状态为2时，需要传 解决措施
      },
      updateRulesCustom: {
        Resolution: [
          {required: true, message: '请选择维护类型', trigger: 'change'}
        ]
      },
      changeState: false,
      isShowProcessRecordDialog: false, // 是否显示处理记录对话框
      maintainType: '',
      isDeal: false,
      isView: false,
      fileList: [],
      role: null,
      locale: []
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['customerTree','clientHeight'])
  },
  created() {
    this.userInfo = getUserInfo().Info
    this.role = sessionStorage.getItem('role')
    let param = {
      StartAt: 0,
      Size: 20,
    }
    this.$post('admin/listLocale', param).then(res => {
      this.loading = false;
      this.locale = res.content
    })
    this.netGetMessageList() // 获取列表
  },
  methods: {
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.dataObj.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handlePreview(file) {
      this.showImg = true
      this.dialogImageUrl = file.response.Data
    },
    handleMapReady() {
      if (this.formData.Lng && this.formData.Lat) {
        this.mapCenter = [this.formData.Lng, this.formData.Lat]
      }
    },
    getPosition(pos) {
      this.formData.Lng = pos.point.lng.toString()
      this.formData.Lat = pos.point.lat.toString()
      this.formData.Position = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.MaintenancePics.push({MaintenanceId: this.formData.Id, PicUrl: res.Data})
    },
    handleExceed(files, fileList) {
      this.$message.warning(`照片数量限制在4张`)
    },
    handleRemove(file, fileList) {
      this.formData.MaintenancePics.forEach((v, index) => {
        if (file.response) {
          if (v.PicUrl === file.response.data) {
            this.formData.MaintenancePics.splice(index, 1)
          }
        } else {
          if (v.PicUrl === file.url) {
            this.formData.MaintenancePics.splice(index, 1)
          }
        }
      })
      const i = this.formData.MaintenancePics.indexOf(file.response.data)
      this.formData.MaintenancePics.splice(i, 1)
    },
    /**
     * @description 日期格式化
     */
    dateFormatter(r, c) {
      return dateFormater(r[c.property], false, false)
    },
    maintenanceStatusFormatter,
    /**
     * @description 获取列表
     */
    netGetMessageList() {
      this.loading = true
      const payload = assembleParam({
        ...this.filter,
        userInfo: this.userInfo
      })
      this.$post('admin/listMaintenance', payload).then(res => {
        this.dataObj.list = res.content
        this.dataObj.total = res.total
        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    },
    /**
     * @description 查询
     */
    searchData() {
      this.postdata.page = 1
      this.netGetMessageList()
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.filter.page = 1
      this.filter.size = pageSize
      this.netGetMessageList()
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.filter.page = page
      this.netGetMessageList()
    },
    /**
     * @description 操作
     */
    handleClick(status, row) {
      switch (status) {
        case '处理记录':
          this.isShowProcessRecordDialog = true
          break
      }
    },
    hasChange() {
      this.changeState = true
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.netGetMessageList()
    },
    handleDeal(index, row) {
      this.dlg.title = '处理'
      this.dlg.visible = true
      this.isDeal = true
      this.index = index
      this.formData = Object.assign({}, row, {
        MaintenancePics: [],
        MaintainAt: row.Status === 2 ? new Date().getTime() : row.MaintainAt
      })
      this.fileList = row.MaintenancePicS
    },
    handleLook(index, row) {
      this.dlg.title = '查看'
      this.dlg.visible = true
      this.isView = true
      this.index = index
      this.formData = Object.assign({}, row, {
        MaintenancePics: []
      })
      // this.formData.Locale = Object.assign({}, this.locale.find(e => e.Id === row.LocaleId))
      this.fileList = row.MaintenancePicS
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.isView = false
      this.isDeal = false
      this.device = []
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.formData = {}
      this.$refs.upload.uploadFiles = []
    },
    /**
     * @description 处理监测点状态
     */
    updateStatus(id, status) {
      this.updatePostdata.loading = true
      this.$post('admin/handleAbnormalData', this.updatePostdata).then(msg => {
        this.$message({
          message: msg || '操作成功',
          type: 'success'
        })
        this.updatePostdata.loading = false
        this.updatePostdata.show = false
        this.$refs.updatePostdata.resetFields()
        this.postdata.page = 1
        this.netGetMessageList()
      }).catch(() => {
        this.updatePostdata.loading = false
        this.$refs.updatePostdata.resetFields()
        this.updatePostdata.show = false
      })
    },

    /**
     * @description 提交表单
     */
    handleSubmit() {
      const uri = 'admin/updateMaintenance'
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              para.MaintainAt = (0 | para.MaintainAt / 1000) // 小数转换为整数
              para.CreateAt = (0 | this.formData.CreateAt / 1000)
              para.AlarmAt = (0 | this.formData.AlarmAt / 1000)
              para.Status = 1
              // para.BeginAt = para.BeginAt / 1000
              this.$post(uri, para)
                .then(() => {
                  this.dlg.visible = false
                })
                .catch(() => {
                })
            })
            .catch(() => {
            })
        }
      })
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const payload = Object.assign(
        {},
        assembleParam({
          ...this.filter,
          userInfo: this.userInfo
        }),
        {
          Size: exportSize,
          IsDownload: true
        }
      )
      this.$post('admin/listMaintenance', payload).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let param = {
          StartAt: 0,
          Size: 20,
          Param: {
            Name: query
          }
        }
        this.$post('admin/listLocale', param).then(res => {
          this.loading = false;
          this.locale = res.content
        })
      }
    }
  }
}
</script>

<style lang="scss">
.abnormal_data_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
}

.view-img {
  width: 140px;
  height: 140px;
  margin: 0 10px;
}

.bm-view {
  width: 100%;
  height: 150px;
}
</style>
